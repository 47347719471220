.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}

.header {
  width: auto;
}

.header p {
  text-align: center;
}

.topnav {
  background-color: #1d2333;
  border-bottom: 1px solid #30373d;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.75rem 1rem 0.75rem;
  align-items: center;
}

.navlogo, .navlinks a {
  font-weight: 500;
}

.navlogo {
  font-size: 1.25rem;
  margin-left: 1rem;
}

.navlinks {
  width: 20rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.apptitle {
  font-size: 2.5rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.appdescription {
  font-size: 1.1rem;
  margin: 1rem;
}

.link {
  font-weight: 500;
}

.cloudform {
  position: relative;
  width: 75vw;
}

.textarea {
  position: relative;
  resize: none;
  font-size: 1.1rem;
  padding: 1rem 2rem 1rem 2rem;
  width: 95%;
  height: 5.4vh;
  border-radius: 0.5rem;
  border: 1px solid #30373d;
  background: #141414;
  color: inherit;
  outline: none;
}

.textarea:disabled {
  opacity: 0.5;
}

/* .textarea::placeholder {
  color: #5f6368;
} */

.generatebutton {
  /* position: absolute; */
  top: 0.87rem;
  right: 1rem;
  color: rgb(165, 162, 162);
  background: none;
  border: none;
  display: flex;
  cursor: pointer;
}

.loadingwheel {
  position: absolute;
  top: 0.2rem;
  right: 0.25rem;
}

.svgicon {
  transform: rotate(90deg);
  width: 1.2em;
  height: 1.2em;
  fill: currentColor;
}

/* .generatebutton:hover {
  background: #1f2227;
  border-radius: 0.2rem;
} */

.generatebutton:disabled {
  opacity: 0.9;
  cursor: not-allowed;
  background: none;
}

.messagelist {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 0.5rem;
}

.messagelistloading {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}

.usermessage {
  /* background: #0e1524; */
  padding: 1.5rem;
  color: #ECECF1;
}

.usermessagewaiting{
  padding: 1.5rem;
  color: #ECECF1;
  background: linear-gradient(to left, #04060c, #0a101f, #060b16);
  background-size: 200% 200%;
  background-position: -100% 0;
  animation: loading-gradient 2s ease-in-out infinite;
  animation-direction: alternate;
  animation-name: loading-gradient;
  -webkit-animation: loading-gradient 2s ease-in-out infinite;
}

@keyframes loading-gradient {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.apimessage {
  /* background: #1d2333; */
  padding: 1.5rem;
  color: #ECECF1;
  animation: fadein 0.5s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.apimessage, .usermessage, .usermessagewaiting {
  display: flex;
}

.markdownanswer {
  line-height: 1.75;
}

.markdownanswer a:hover {
  opacity: 0.8;
}

.markdownanswer a {
  color: #16bed7;
  font-weight: 500;
}

.markdownanswer code {
  color: #15cb19;
  font-weight: 500;
  white-space: pre-wrap !important;
}

.markdownanswer ol, .markdownanswer ul {
  margin: 1rem;
}

.boticon, .usericon {
  margin-right: 1rem;
  border-radius: 0.1rem;
  margin-top: 10px;
}

.markdownanswer h1, .markdownanswer h2, .markdownanswer h3 {
  font-size: inherit;
}


.center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  flex-direction: column;
}

.cloud {
  width: 75vw;
  height: calc(100vh - 550px);
  background: transparent;
  border-radius: 0.5rem;
  border: 1px solid #30373d;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pointsnormal {
  width: 90%;
  height: 90%;
}

.pointsdim {
  width: 90%;
  height: 90%;
  opacity: 0.25;
}

.footer {
  color: #5f6368;
  font-size: 0.8rem;
  margin: 1.5rem;
}

.footer a {
  font-weight: 500;
  color: #7a7d81;
}

.footer a:hover {
  opacity: 0.8;
}

/* Mobile optimization */
@media (max-width: 600px) {

  .main {
    padding: 1rem;
    max-height: 90vh;
  }

  .cloud {
    width: 22rem;
    height: 28rem;
  }
  .textarea {
    width: 22rem;
  }
  .topnav {
    border: 1px solid black;
    align-items: center;
    padding: 0.85rem 0.75rem 0.85rem 0.75rem;
  }

.navlogo {
  font-size: 1.25rem;
  width: 20rem;
}

.markdownanswer code {
  white-space : pre-wrap !important;
}

.footer {
  font-size: 0.7rem;
  width: 100%;
  text-align: center;
}
}